import { Routes, Route, Navigate } from "react-router-dom";

import { ThemeProvider } from "@fluentui/react";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { APP_THEME } from "Tools/theme";
import { LINKS } from "Tools/navigation";
import { ProtectedView } from "Components/guards/ProtectedView";
import { AuthContext } from "Data/auth/context";
import { useAuthProvider } from "Data/auth/hooks";
import { NoAuthView } from "Components/guards/NoAuthView";
import { lazy } from "react";
import dayjs from "dayjs";
import * as relativeTime from "dayjs/plugin/relativeTime";
import { GlobalDiallogContext } from "Data/dialog/context";
import { useGlobalialog } from "Data/dialog/hooks";
import { SuspenseLoadingUI } from "Components/SuspenseLoadingUI/SuspenseLoadingUI";
import { hasAnyPermissions, hasPermissions } from "Tools/user/shared";
import { LoadingResourceError } from "Components/LoadingResourceError/LoadingResourceError";
import { PERMISSIONS } from "Tools/permissions";
import "App.css";


const MainInterface = lazy(() => import("Pages/main-interface/MainInterfaceRoot"));

const FrostRoot = lazy(() => import("Pages/main-interface/FrostRoot"));
const ForecastRoot = lazy(() => import("Pages/main-interface/frost/ForecastRoot"));
const ForecastList = lazy(() => import("Pages/main-interface/frost/forecast/ForecastList"));
const ForecastAdd = lazy(() => import("Pages/main-interface/frost/forecast/ForecastAdd"));
const ReservationRoot = lazy(() => import("Pages/main-interface/frost/ReservationRoot"));
const ReservationReport = lazy(() => import("Pages/main-interface/frost/reservation/ReservationReport"));
const FROSTOrderingRoot = lazy(() => import("Pages/main-interface/frost/OrderingRoot"));
const FROSTOrderingSampleManagement = lazy(() => import("Pages/main-interface/frost/ordering/SampleManagement"));
const FROSTOrderingCreate = lazy(() => import("Pages/main-interface/frost/ordering/OrderingCreate"));
const FROSTOrderingReport = lazy(() => import("Pages/main-interface/frost/ordering/OrderingReport"));
const FROSTOrderingAssign = lazy(() => import("Pages/main-interface/frost/ordering/OrderingAssign"));
const FROSTTDManagement = lazy(() => import('Pages/main-interface/frost/ordering/TDManagement'))
const FROSTShippingRoot = lazy(() => import("Pages/main-interface/frost/ShippingRoot"));
const FROSTShippingReport = lazy(() => import("Pages/main-interface/frost/shipping/ShippingReport"));
const TrackingRoot = lazy(() => import("Pages/main-interface/frost/TrackingRoot"));
const TrackingGEPReport = lazy(() => import("Pages/main-interface/frost/tracking/Report"));
const FROSTOrderingDataFlags = lazy(() => import("Pages/main-interface/frost/ordering/FlaggedData"));

const ChemicalsStockRoot = lazy(() => import("Pages/main-interface/ChemicalsStockRoot"));
const EquipmentStockRoot = lazy(() => import("Pages/main-interface/EquipmentStockRoot"));
const AddChemicalProduct = lazy(() => import("Pages/main-interface/chemicals-stock/AddChemicalProduct"));
const ViewChemicalProducts = lazy(() => import("Pages/main-interface/chemicals-stock/ViewChemicalProducts"));
const ChemnicalProductOrders = lazy(() => import("Pages/main-interface/chemicals-stock/order-management/OrderManagement"));
const ChemicalBranchManagementRoot = lazy(() => import("Pages/main-interface/chemicals-stock/BranchManagementRoot"));
const ChemicalBranches = lazy(() => import("Pages/main-interface/chemicals-stock/branch-management/Branches"));
const ChemicalBranchesMembers = lazy(() => import("Pages/main-interface/chemicals-stock/branch-management/AssignedMembers"));
const SettingsRoot = lazy(() => import("Pages/main-interface/SettingsRoot"));
const ApplicationSettingsRoot = lazy(() => import("Pages/main-interface/settings/ApplicationSettingsRoot"));
const DeveloperConsoleSettings = lazy(() => import("Pages/main-interface/settings/application/DeveloperConsole/DeveloperConsole"));
const FROSTSettings = lazy(() => import("Pages/main-interface/settings/application/FROSTSettings"));
const MyAccountSettings = lazy(() => import("Pages/main-interface/settings/MyAccount"));
const EquipmentStockSettings = lazy(() => import("Pages/main-interface/settings/application/EquipmentStockSettings"));
const ChemicalStockSettings = lazy(() => import("Pages/main-interface/settings/application/ChemicalsStockSettings"));
const ChemicalsTrackingGEPReport = lazy(() => import("Pages/main-interface/chemicals-stock/tracking/TrackingManagement"));
const ChemicalsTrackingRoot = lazy(() => import("Pages/main-interface/chemicals-stock/TrackingRoot"));


const DeviceDemoGraphics = lazy(() => import("Pages/main-interface/equipment-stock/DeviceApprovalManagement"));
const DeviceAchieves = lazy(() => import("Pages/main-interface/equipment-stock/DeviceArchives"));
const AssignedDevices = lazy(() => import("Pages/main-interface/equipment-stock/devices/AssignedDevices"));
const DeviceRequests = lazy(() => import("Pages/main-interface/equipment-stock/devices/DeviceRequests"));
const DeviceMasterList = lazy(() => import("Pages/main-interface/equipment-stock/devices/DeviceMasterList"));
const Devices = lazy(() => import("Pages/main-interface/equipment-stock/Devices"));
const AllDevices = lazy(() => import("Pages/main-interface/equipment-stock/devices/AllDevices"));


const SeedcareRoot = lazy(() => import("Pages/main-interface/SeedCareRoot"));
const SeedcareSeedTreatmentRoot = lazy(() => import("Pages/main-interface/seedcare/SeedTreatmentRoot"));
// const SeedcareSeedTreatmentRequest = lazy(() => import("Pages/main-interface/seedcare/seed-treatment/Request"));
const SeedcareSeedTreatmentRTMReport = lazy(() => import("Pages/main-interface/seedcare/seed-treatment/RTMReport"));
const SeedcareSeedTreatmentBlockedDemandIDs = lazy(() => import("Pages/main-interface/seedcare/seed-treatment/BlockedDemandIDs/BlockedDemandIDs"));
const SeedcareSeedTreatmentSTFShipmentSheet = lazy(() => import("Pages/main-interface/seedcare/seed-treatment/STFShipmentSheet"));
const SeedcareSeedTreatmentRequestList = lazy(() => import("Pages/main-interface/seedcare/seed-treatment/AssignmentsList"));
const SeedcareInfectedSeedsDBRoot = lazy(() => import('Pages/main-interface/seedcare/InfectedSeedsDatabaseRoot'))
const SeedcareInfectedSeedsDBManagement = lazy(() => import('Pages/main-interface/seedcare/infected-seeds-database/Management'))

const PeopleRoot = lazy(() => import("Pages/main-interface/PeopleRoot"));
const Index = lazy(() => import("Pages/main-interface/Index/Index"));
const Login = lazy(() => import("Pages/auth/Login"));
const SSOSignIn = lazy(() => import("Pages/auth/SSOSignIn"));
const AccountRecovery = lazy(() => import("Pages/auth/AccountRecovery"));

const UtilitiesRoot = lazy(() => import("Pages/main-interface/StatistictRoot"));
const SeedcareDeepDiveRoot = lazy(() => import("Pages/main-interface/utilities/SeedcareDeepDiveRoot"));
const SeedcareDeepDiveSubmit = lazy(() => import("Pages/main-interface/utilities/seedcare-deep-dive/Submit"));
const SeedcareDeepDiveHistory = lazy(() => import("Pages/main-interface/utilities/seedcare-deep-dive/History"));

const DataQualityRoot = lazy(() => import("Pages/main-interface/utilities/DataQualityRoot"));
const DataQualityAuditTable = lazy(() => import("Pages/main-interface/utilities/data-quality/AuditTable/AuditTable"));
const DataQualityRuleBook = lazy(() => import("Pages/main-interface/utilities/data-quality/RuleBook"));


const ActivitiesRoot = lazy(() => import("Pages/main-interface/ActivitiesRoot"));
const ActivitiesPlanningRoot = lazy(() => import("Pages/main-interface/activity-center/PlanningRoot"));
const ActivitiesPlanningCalendar = lazy(() => import("Pages/main-interface/activity-center/Planning/PlanningCalendar/PlanningCalendar"));
const ActivitiesPlanningOverview = lazy(() => import("Pages/main-interface/activity-center/Planning/Overview/Overview"));


initializeIcons();



dayjs.extend(relativeTime.default);

type ILoadableProps = {
  children: JSX.Element,
  user?: User | null,
  permissions?: string[]
  anyPermissions?: string[]
}
let Loadable = ({ user, permissions = [], anyPermissions = [], children }: ILoadableProps) => {
  let perms = permissions || []
  let anyPerms = anyPermissions || []
  let component = <SuspenseLoadingUI>{children}</SuspenseLoadingUI>
  let enforcePermissions = perms.length || anyPermissions.length
  if (enforcePermissions) return enforcePermissions && user
    && (anyPerms.length ? hasAnyPermissions(user!, anyPerms) : hasPermissions(user!, perms)) ? component : <LoadingResourceError retry={false} />
  else return component

}

function App() {
  let { user, setUser, loading, signIn, signOut } = useAuthProvider();
  let gNotif = useGlobalialog();

  return (
    <ThemeProvider theme={APP_THEME}>
      <GlobalDiallogContext.Provider value={gNotif}>
        <AuthContext.Provider value={{ user, setUser, loading, signIn, signOut }}>
          <Routes>
            <Route element={<Loadable><ProtectedView><MainInterface /></ProtectedView></Loadable>} path={LINKS.home}>

              <Route element={<Loadable user={user} permissions={PERMISSIONS.FROST.PERMISSIONS}><FrostRoot /></Loadable>} path={LINKS.$frost}>
                {/* Frost forecast routes */}
                <Route element={<Loadable  {...{ user, anyPermissions: PERMISSIONS.FROST.Forecast.ANY_PERMISSIONS }}><ForecastRoot /></Loadable>} path={LINKS.frost.$forecast}>
                  <Route element={<Loadable><ForecastList /></Loadable>} path={LINKS.frost.forecast.$list} />
                  <Route element={<Loadable  {...{ user, anyPermissions: PERMISSIONS.FROST.Forecast.Add.ANY_PERMISSIONS }}><ForecastAdd /></Loadable>} path={LINKS.frost.forecast.$add} />
                </Route>

                {/* Frost reservation routes */}
                <Route element={<Loadable user={user} anyPermissions={PERMISSIONS.FROST.Reservation.ANY_PERMISSIONS}><ReservationRoot /></Loadable>} path={LINKS.frost.$reservation}>
                  <Route element={<Loadable><ReservationReport /></Loadable>} path={LINKS.frost.reservation.$report} />
                  <Route element={<Loadable user={user} anyPermissions={PERMISSIONS.FROST.Reservation.SampleManagement.ANY_PERMISSIONS}><FROSTOrderingSampleManagement /></Loadable>} path={LINKS.frost.reservation.$sampleManagement} />
                </Route>

                <Route element={<Loadable user={user} anyPermissions={PERMISSIONS.FROST.Ordering.ANY_PERMISSIONS}><FROSTOrderingRoot /></Loadable>} path={LINKS.frost.$ordering} >
                  <Route element={<Loadable user={user} anyPermissions={PERMISSIONS.FROST.Ordering.Assign.ANY_PERMISSIONS}><FROSTOrderingAssign /></Loadable>} path={LINKS.frost.ordering.$assign} />
                  <Route element={<Loadable user={user} anyPermissions={PERMISSIONS.FROST.Ordering.Create.ANY_PERMISSIONS}><FROSTOrderingCreate /></Loadable>} path={LINKS.frost.ordering.$create} />
                  <Route element={<Loadable user={user} anyPermissions={PERMISSIONS.FROST.Ordering.Report.ANY_PERMISSIONS}><FROSTOrderingReport /></Loadable>} path={LINKS.frost.ordering.$report} />
                  <Route element={<Loadable user={user} anyPermissions={PERMISSIONS.FROST.Ordering.TDManagement.ANY_PERMISSIONS}><FROSTTDManagement /></Loadable>} path={LINKS.frost.ordering.$tdmanagement} />
                  <Route element={<Loadable user={user} permissions={PERMISSIONS.FROST.Ordering.FlaggedData.PERMISSIONS}><FROSTOrderingDataFlags /></Loadable>} path={LINKS.frost.ordering.$flaggedData} />
                </Route>

                <Route element={<Loadable user={user} anyPermissions={PERMISSIONS.FROST.Tracking.ANY_PERMISSIONS}><TrackingRoot /></Loadable>} path={LINKS.frost.$tracking} >
                  <Route element={<Loadable><TrackingGEPReport /></Loadable>} path={LINKS.frost.tracking.report} />
                </Route>

                <Route element={<Loadable><FROSTShippingRoot /></Loadable>} path={LINKS.frost.$shipping}>
                  <Route element={<Loadable><FROSTShippingReport /></Loadable>} path={LINKS.frost.shipping.$report} />
                </Route>
              </Route>


              <Route element={<Loadable {...{ user, anyPermissions: PERMISSIONS.Chemicals.ANY_PERMISSIONS }}><ChemicalsStockRoot /></Loadable>} path={LINKS.$chemicalStock}>
                <Route element={<Loadable {...{ user, anyPermissions: PERMISSIONS.Chemicals.Products.Add.ANY_PERMISSIONS }} ><AddChemicalProduct /></Loadable>} path={LINKS.chemicalStock.$addProduct} />
                <Route element={<Loadable><ViewChemicalProducts /></Loadable>} path={LINKS.chemicalStock.$viewProducts} />
                <Route element={<Loadable {...{ user, anyPermissions: PERMISSIONS.Chemicals.Tracking.ANY_PERMISSIONS }}  ><ChemicalsTrackingRoot /></Loadable>} path={LINKS.chemicalStock.$tracking}>
                  <Route element={<Loadable><ChemicalsTrackingGEPReport /></Loadable>} path={LINKS.chemicalStock.tracking.gepReport} />
                </Route>
                <Route element={<Loadable><ChemnicalProductOrders /></Loadable>} path={LINKS.chemicalStock.$productOrders} />
                <Route element={<Loadable {...{ user, anyPermissions: PERMISSIONS.Chemicals.Branches.ANY_PERMISSIONS }} ><ChemicalBranchManagementRoot /></Loadable>} path={LINKS.chemicalStock.$branches}>
                  <Route element={<Loadable  {...{ user, anyPermissions: PERMISSIONS.Chemicals.Branches.List.ANY_PERMISSIONS }}><ChemicalBranches /></Loadable>} path={LINKS.chemicalStock.branches.$list} />
                  <Route element={<Loadable><ChemicalBranchesMembers /></Loadable>} path={LINKS.chemicalStock.branches.$members} />
                </Route>
              </Route>

              {/* Equipment Stock Routes */}
              <Route element={<Loadable><EquipmentStockRoot /></Loadable>} path={LINKS.$equipmentStock}>
                <Route element={<Loadable><Devices /></Loadable>} path={LINKS.equipmentStock.$devices}>
                  <Route element={<Loadable><AllDevices /></Loadable>} path={LINKS.equipmentStock.devices.$allDevices} />
                  <Route element={<Loadable {...{ user, anyPermissions: PERMISSIONS.Equipment.ANY_APPROVE_REJECT_REQUEST }}><DeviceRequests /></Loadable>} path={LINKS.equipmentStock.devices.$deviceRequests} />
                  <Route element={<Loadable><AssignedDevices /></Loadable>} path={LINKS.equipmentStock.devices.$assignedDevices} />
                  <Route element={<Loadable><DeviceMasterList /></Loadable>} path={LINKS.equipmentStock.devices.$deviceMasterList} />
                </Route>
                <Route element={<Loadable><DeviceAchieves /></Loadable>} path={LINKS.equipmentStock.$deviceUsage} />
                <Route element={<Loadable><DeviceDemoGraphics /></Loadable>} path={LINKS.equipmentStock.$deviceApproval} />
              </Route>


              {/* Seedcare routes */}
              <Route element={<Loadable {...{ user, permissions: PERMISSIONS.Seedcare.PERMISSIONS }}><SeedcareRoot /></Loadable>} path={LINKS.$seedcare}>
                <Route element={<Loadable /*{...{ user, anyPermissions: PERMISSIONS.Seedcare. }}*/><SeedcareSeedTreatmentRoot /></Loadable>} path={LINKS.seedCare.$seedTreatment}>
                  {/* <Route element={<Loadable {...{ user, anyPermissions: PERMISSIONS.Seedcare.TreatmentRequest.ANY_PERMISSIONS }}><SeedcareSeedTreatmentRequest /></Loadable>} path={LINKS.seedCare.seedTreatment.$createRequests} /> */}
                  <Route element={<Loadable {...{ user, anyPermissions: PERMISSIONS.Seedcare.TreatmentRequest.ANY_PERMISSIONS }}><SeedcareSeedTreatmentRTMReport /></Loadable>} path={LINKS.seedCare.seedTreatment.$rtmReport} />
                  <Route element={<Loadable {...{ user, anyPermissions: PERMISSIONS.Seedcare.TreatmentAssignment.ANY_PERMISSIONS }}><SeedcareSeedTreatmentRequestList /></Loadable>} path={LINKS.seedCare.seedTreatment.$assignmentsList} />
                  <Route element={<Loadable {...{ user, anyPermissions: PERMISSIONS.Seedcare.STFShipping.ANY_PERMISSIONS }}><SeedcareSeedTreatmentSTFShipmentSheet /></Loadable>} path={LINKS.seedCare.seedTreatment.$stfShipping} />
                  <Route element={<Loadable  {...{ user, anyPermissions: PERMISSIONS.user.ADMIN_SEEDCARE }} ><SeedcareSeedTreatmentBlockedDemandIDs /></Loadable>} path={LINKS.seedCare.seedTreatment.$blockedDemandIDs} />
                </Route>
                <Route element={<Loadable {...{ user, anyPermissions: PERMISSIONS.Seedcare.InfectedSeeds.ANY_PERMISSIONS }} ><SeedcareInfectedSeedsDBRoot /></Loadable>} path={LINKS.seedCare.$infectedSeedsDatabase}>
                  <Route element={<Loadable ><SeedcareInfectedSeedsDBManagement /></Loadable>} path={LINKS.seedCare.infectedSeedsDatabase.$management} />
                </Route>
              </Route>


              {/* Utilities routes */}
              <Route element={<Loadable {...{ user, anyPermissions: PERMISSIONS.Utilities.ANY_PERMISSIONS }}><UtilitiesRoot /></Loadable>} path={LINKS.$utilities}>
                <Route element={<Loadable ><SeedcareDeepDiveRoot /></Loadable>} path={LINKS.utilities.$seedcareDeepDive} >
                  <Route element={<Loadable ><SeedcareDeepDiveSubmit /></Loadable>} path={LINKS.utilities.seedcareDeepDive.$submit} />
                  <Route element={<Loadable ><SeedcareDeepDiveHistory /></Loadable>} path={LINKS.utilities.seedcareDeepDive.$history} />
                  <Route path="**" element={<Navigate to={LINKS.utilities.seedcareDeepDive.submit} />} />
                </Route>

                <Route element={<Loadable {...{ user, anyPermissions: PERMISSIONS.Utilities.DataQuality.ANY_PERMISSIONS }}><DataQualityRoot /></Loadable>} path={LINKS.utilities.$dataQuality}>
                  <Route element={<Loadable ><DataQualityAuditTable /></Loadable>} path={LINKS.utilities.dataQuality.auditTable} />
                  <Route element={<Loadable ><DataQualityRuleBook /></Loadable>} path={LINKS.utilities.dataQuality.ruleBook} />
                </Route>
              </Route>



              <Route element={<Loadable {...{ user, permissions: PERMISSIONS.Activity.PERMISSIONS }}><ActivitiesRoot /></Loadable>} path={LINKS.$activities}>
                <Route element={<Loadable ><ActivitiesPlanningRoot /></Loadable>} path={LINKS.activities.$planning} >
                  <Route element={<Loadable ><ActivitiesPlanningCalendar /></Loadable>} path={LINKS.activities.planning.$calendar} />
                  <Route element={<Loadable ><ActivitiesPlanningOverview /></Loadable>} path={LINKS.activities.planning.$overview} />
                </Route>
              </Route>


              <Route element={<Loadable  {...{ user, anyPermissions: PERMISSIONS.People.ANY_PERMISSIONS }}><PeopleRoot /></Loadable>} path={LINKS.$people} />

              <Route element={<Loadable><Index /></Loadable>} path="/" />

              <Route element={<Loadable {...{ user, permissions: [] }}><SettingsRoot /></Loadable>} path={LINKS.$settings}>
                <Route path={LINKS.settings.$myAccount} element={<Loadable><MyAccountSettings /></Loadable>} />
                <Route element={<Loadable ><ApplicationSettingsRoot /></Loadable>} path={LINKS.settings.$application}>
                  <Route element={<Loadable {...{ user, permissions: ["frost_index.access_frost_app"] }}><FROSTSettings /></Loadable>} path={LINKS.settings.application.$frost} />
                  <Route element={<Loadable {...{ user, permissions: ["frost_index.access_equipment_app"] }}><EquipmentStockSettings /></Loadable>} path={LINKS.settings.application.$equipmentStock} />
                  <Route element={<Loadable {...{ user, permissions: ["frost_index.access_chemicals_app"] }}><ChemicalStockSettings /></Loadable>} path={LINKS.settings.application.$chemicalsStock} />
                  <Route element={<Loadable {...{ user, permissions: PERMISSIONS.user.SYSTEM }} ><DeveloperConsoleSettings/></Loadable>}  path={LINKS.settings.application.$developerConsole}/>
                  <Route path="**" element={<Navigate to={LINKS.settings.application.frost} />} />
                </Route>

              </Route>



            </Route>



            <Route element={<Loadable><NoAuthView><Login /></NoAuthView></Loadable>} path={LINKS.$login} />
            <Route element={<Loadable><NoAuthView><SSOSignIn /></NoAuthView></Loadable>} path={LINKS.$SSOSignIn} />
            <Route element={<Loadable><NoAuthView><AccountRecovery /></NoAuthView></Loadable>} path={LINKS.$accountRecovery} />
          </Routes>
        </AuthContext.Provider>
      </GlobalDiallogContext.Provider>
    </ThemeProvider>
  );
}

export default App;
